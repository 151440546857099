.wrapper {
  background-color: #ffffff;
  width: 90%;
  margin: 0 auto;
}

.transit {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transit p {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
}

@media screen and (max-width: 576px) {
  .transit p {
    padding: 1rem;
    font-size: 0.9rem;
  }
}