.video-container {
  padding-top: 2rem;
}

.video-container h2 {
  font-size: 3rem;
  text-align: center;
  color: #1f79ff;
}

.see-more-videos {
  text-align: center;
}

.see-more-videos p {
  margin: 1rem 0 0.5rem 0;
  font-size: 1.1rem;
}

.fix-video-display {
  width: 100%;
  height: 85%;
  object-fit: contain;
}

/* video page */
.video-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: auto;
}

.video-list iframe {
  width: 300px;
  height: 200px;
  border-radius: 7px;
}



/* end of video page */

@media screen and (max-width: 576px) {
  .fix-video-display {
    width: 100%;
    height: 85%;
    object-fit: contain;
  }

  .swiper-container {
    width: 80%;
  }
}