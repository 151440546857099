.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background-color: #ff9408;
}

.wrapper-gap-left {
  margin-left: 7rem;
}

.wrapper-gap-right {
  margin-right: 7rem;
}

.navbar a {
  text-decoration: none;
}

.white-clr {
  color: #fff;
}

/* .all-nav-links {
  display: flex;
  gap: 15px;
  list-style: none;
  background-color: #1f79ff;
  padding: 0.8rem 1rem;
  border-radius: 7px;
}

.all-nav-links li a {
  color: white;
}

.all-nav-links li a:active {
  background-color: white;
  color: #ff9408;
}

.all-nav-links li a:hover {
  background: white;
  color: #1f79ff;
  padding: 0.2rem;
  border-radius: 4px;
} */

.social-media-links {
  display: flex;
  gap: 10px;
}

.social-media-links a {
  color: white;
}

.slider-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Styled Burger */
/* .styled-burger {
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 1rem;
  right: 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
}

.styled-burger div {
  width: 2rem;
  height: 0.25rem;
  background-color: #ffffff;
  border-radius: 7px;
  transform-origin: 1px;
} */



/* End of Styled Burger */

/* @media screen and (max-width: 576px) {
  .all-nav-links {
    width: 100%;
  }

  .all-nav-links ul {
    display: none;
  }

  .wrapper-gap-left {
    margin-left: 0.2rem;
  }
} */

/* @media screen and (max-width: 768px) {
  .all-nav-links {
    display: none;
    flex-flow: column nowrap;
    position: fixed;
    top: 0;
    right: 0;
    width: 200px;
    height: 100vh;
    z-index: 100;
    margin-right: 0;
    border-radius: 0;
    padding-top: 4.5rem;
  }

  .all-nav-links li:hover a {
    background: white;
    display: block;
    width: 100%;
    padding: 0.2rem;
    border-radius: 4px;
    color: #1f79ff;
  }
} */

@media screen and (max-width: 992px) {}

@media screen and (max-width: 1200px) {}