.no-wrapper {
  background-color: #ffffff;
  width: 100% !important;
  margin: 0 auto;
}
.footer {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}
.footer-separator {
  width: 90%;
  height: 4px;
  margin: 2rem auto;
  background-color: orange;
}
.more-footer-info {
  display: flex;
  justify-content: space-between;
  margin: 0 2rem;
}
.more-footer-info p {
  color: white;
  font-weight: 500;
}
.yt,
.telegram,
.fb {
  opacity: 0.8;
}
.yt:hover,
.fb:hover,
.telegram:hover {
  opacity: 1;
}
