* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nokia Pure Headline";
}

body {
  background-color: #fdf7eb;
}

/* about */
.short-description {
  width: 80%;
  margin: 0 auto;
}

.short-description p {
  line-height: 1.7rem;
  text-align: justify;
}

.short-description p::first-letter {
  font-size: 5rem;
  color: orangered;
}

/* end of about */
/* faq */
.faqs {
  width: 80%;
  margin: 0 auto;
}

.accordion {
  width: 100%;
}

.accordion-item {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 7px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  color: orangered;
}

/* .accordion-content{

} */
hr {
  background-color: #f3f3f3;
  border: none;
  border-radius: 7px;
  height: 1px;
  margin: 0.5rem 0;
}

.handleIcon {
  cursor: pointer;
}

/* end of faq */
/* books */
.book-container {
  width: 80%;
  margin: 0 auto;
}

.book {
  width: 220px;
}

.book-img {
  box-shadow: 1px 1px 10px 10px rgba(255, 223, 196, 0.8);
  border-radius: 7px;
  border: 3px solid #ee7d54;
  width: 220px;
  height: 300px;
  object-fit: contain;
}

.book-price-and-actions {
  display: flex;
  justify-content: space-between;
}

/* .title-and-price{

} */
.book-title {
  font-size: 1.1rem;
  font-weight: bold;
  color: #0082ff;
}

.book-price {
  color: orangered;
  font-weight: bold;
}

.checkout-book {
  background: #2ae870;
  width: 50px;
  padding: 0.5rem;
  border-radius: 4px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.checkout-book:hover {
  background-color: #00ff5e;
  border-radius: 50px;
  filter: blur(0.1px);
}

/* end of books */
/* audio */
.audio {
  /* border: 1px solid green; */
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.single-audio {
  width: 300px;
  /* border: 1px solid red; */
  display: flex;
  gap: 20px;
}

.single-audio img {
  width: 90px;
  height: 90px;
  border: 2px solid orange;
  border-radius: 50%;
  object-fit: contain;
}

/* .audio-options{

} */
.audio-title {
  color: #0082ff;
  font-weight: bold;
}

.download-size {
  font-size: 0.8rem;
  font-weight: bold;
  color: #0082ff;
  margin-top: 0.3rem;
}

.download-btn {
  width: 110px;
  max-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: #2ae870;
  border-radius: 30px;
  padding: 0.45rem 0.1rem;
  margin-top: 10px;
}

.download {
  padding: 0 0.3rem;
  font-size: 0.8rem;
  color: white;
  font-weight: 500;
}

.download-btn:hover {
  filter: blur(.1px);
  background-color: #00ff5e;
  box-shadow: 1px 1px 5px 1px #00ff5e;
}

/* end of audio */


@media screen and (max-width: 576px) {
  .faqs {
    width: 90%;
  }

}

@media screen and (max-width: 768px) {}