.blog-container {
  padding-top: 2rem;
}

.blog-container h2 {
  font-size: 3rem;
  text-align: center;
  color: #1f79ff;
}

.sectionTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.underblock {
  width: 140px;
  height: 4px;
  border-radius: 10px;
  background-color: orange;
}

.separator {
  width: 80%;
  height: 2px;
  margin: 2rem auto;
  background-color: rgb(243, 243, 243);
}

.blog-card {
  display: flex;
  gap: 1rem;
  width: 80%;
  margin: 0 auto;
}

.blog-image {
  width: 200px;
  height: 200px;
  border-radius: 7px;
}

.actual-blog-title {
  text-align: left !important;
  color: orangered !important;
  font-size: 1.7rem !important;
  text-decoration: none;
  font-weight: bold;
}

.actual-blog-title:hover {
  color: #1f79ff !important;
}

.blog-detail {
  line-height: 1.8rem;
  text-overflow: ellipsis;
  margin-bottom: 1rem;
  text-align: justify;
}

.read-more-option {
  color: #1f79ff;
}

.read-more-option:hover {
  color: orangered;
}

/* pagination for blogs */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.page-item {
  list-style-type: none;
  padding: 0.2rem;
  border-radius: 3px;
}

.page-item.active {
  background-color: orange;
  border: none;
}

.page-item:hover {
  background-color: orange;
  color: white;
}

.page-link {
  text-decoration: none;
}

.page-item-number {
  border-radius: 10px;
  width: 30px;
  height: 30px;
  text-align: center;
}

/* blog detail page */
.breadcrumbs-wrapper {
  padding: 1rem 0;
  /* border: 1px solid orange; */
  width: 80%;
  margin: 1rem auto 0;
}

.breadcrumbs {
  text-decoration: none;
  color: red;
}

.b-separator {
  width: 80%;
  height: 2px;
  border-radius: 5px;
  margin: 0 auto 2rem;
  background-color: rgba(0, 0, 0, 0.141);
}

.single-crumb {
  display: flex;
  gap: 10px;
}

.crumb-crumb {
  text-decoration: none;
  color: #1f79ff;
}

.crumb:after {
  content: "/";
  margin-left: 10px;
}

.crumb:last-child:after {
  display: none;
}

.crumb-crumb:hover {
  color: orangered;
  text-decoration: underline;
}

/* end of blog detail page */
.fix-blog-view {
  display: flex;
  gap: 5px;
}

.fix-blog-title-on-detail-page {
  font-size: 2rem;
  font-weight: 700;
  color: orangered;
  margin-bottom: 1rem;
}

.main-blog-list {
  flex: 75%;
}

.blog-categories {
  flex: 25%;
  margin-top: 6rem;
  margin-right: 4rem;
  /* border: 1px solid red; */
}

.blog-categories h3 {
  font-size: 2rem;
  color: #1f79ff;
  /* border-bottom: 1px solid #a0c6ff; */
}

.list-of-categories {
  display: flex;
  flex-direction: column;
}

.category-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 0 1rem;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  box-shadow: 1px 1px 10px 2px #a0c6ff;
  background-color: #1f79ff;
  filter: blur(0.2px);
  color: white;
  text-decoration: none;
}

.category-name {
  font-weight: 500;
}

/* blog detail page */
.blog-detail-containerbox {
  display: flex;
  gap: 2rem;
  margin: 0 auto;
  width: 80%;
  padding: 0.5rem 0 1rem;
}

.more-from-blogs {
  width: 30%;
}

.more-from-blogs .list-of-categories a {
  padding: 0.5rem;
  height: 55px;
}

.more-from-blogs .list-of-categories a p {
  font-size: 0.8rem;
  font-weight: normal;
}

.blog-more-detail {
  width: 80%;
  padding: 0.5rem;
}

.fix-detail-show {
  text-align: justify;
  text-justify: distribute;
}

.fix-spacing-for-pagination {
  margin-top: 1.5rem;
}

/* end of blog detail */

@media screen and (max-width: 576px) {
  .blog-card {
    flex-flow: column nowrap;
  }

  .blog-detail {
    text-align: justify;
  }

  .blog-image {
    width: 100%;
    object-fit: contain;
  }

  .fix-blog-view {
    flex-flow: column nowrap;
  }

  .main-blog-list {
    order: 2;
  }

  .blog-categories {
    margin: 1rem 1.5rem;
  }

  .blog-detail-containerbox {
    flex-flow: column nowrap;
  }

  .more-from-blogs {
    width: 100%;
  }

  .more-from-blogs .list-of-categories a p {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .blog-more-detail {
    order: 2;
    width: 100%;
  }

  .breadcrumbs-wrapper {
    width: 90%;
  }

  .b-separator {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .fix-blog-view {
    flex-flow: column nowrap;
  }

  .blog-categories {
    margin: 1rem 1.5rem;
  }


}