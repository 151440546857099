.wrapper {
  background-color: #ffffff;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 2rem;
}

.slider-img-fix {
  object-fit: contain;
}

.support-overlay {
  position: relative;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.338);
}

.swiper-button-next,
.swiper-button-prev {
  color: orangered;
  font-weight: bold;
}

@media only screen and (max-width: 576px) {}

@media only screen and (max-width: 768px) {}

@media only screen and (max-width: 992px) {}

@media only screen and (max-width: 1200px) {}